import { graphql } from "gatsby"
import React from "react"
import "../assets/styles/index.scss"

import StaticRelatedCaseStudy from "../components/case-studies/related/static"
import Scroll from "../components/global/scroll"
import TopNavigation from "../components/global/top_navigation"
import MainLayout from "../layouts/main_layout"

import Footer from "../components/footer/"
import Head from "../components/global/head"
import styles from "./form.module.scss"

const Application = ({ data: { allDatoCmsCaseStudy } }) => {
  return (
    <MainLayout>
      <Head
        description="Great! Thanks for getting in touch! A member of the team will be in contact soon!"
        title="Thanks!"
        url="https://hardhat.com.au/thanks"
        image="https://hardhat.com.au/images/og.jpg"
      />
      <TopNavigation theme="dark" isHome={false} hideChat={true} />
      <div
        className={`${styles.content} ${styles.contentAlt} container text-center`}
      >
        <h1 className="mega1" style={{ marginBottom: "5vh" }}>
          Thank <span className="script text-yellow">you</span>
        </h1>
        <div className={styles.thankyouContent}>
          <p className="body1">
            The email’s on it’s way. If you don’t see it in the next minute or
            two, please check your spam folder (but you already knew that).
          </p>
          <br />
          <p className="body1">
            Or stick around, we've got plenty of work we love showing off that
            we think your curious mind will enjoy too.
          </p>
        </div>
      </div>

      <StaticRelatedCaseStudy {...allDatoCmsCaseStudy.edges[0].node} />

      <Footer />
    </MainLayout>
  )
}
export default Application

export const query = graphql`
  query {
    allDatoCmsCaseStudy(limit: 1) {
      edges {
        node {
          slug
          title
          titleHighlight
          highlightColour {
            alpha
            blue
            green
            red
          }
          preview {
            colour {
              alpha
              blue
              green
              red
            }
            image {
              url
            }
            highlightColour {
              alpha
              blue
              green
              red
            }
          }
        }
      }
    }
  }
`
