import { Link } from "gatsby"

import React from "react"

import styles from "./related.module.scss"

interface Props {
  slug: string
  title: string
  titleHighlight: string
  preview: {
    image: {
      url: string
    }
    colour: {
      red: string
      green: string
      blue: string
      alpha: string
    }
    highlightColour: {
      red: string
      green: string
      blue: string
      alpha: string
    }
  }
}

const StaticRelatedCaseStudy: React.FC<Props> = ({
  slug,
  title,
  titleHighlight,
  preview,
}) => {
  return (
    <Link
      to={`/case-studies/${slug}`}
      className={styles.columns}
      style={{
        backgroundColor: `rgba(${preview[0].colour.red},${preview[0].colour.green},${preview[0].colour.blue},${preview[0].colour.alpha})`,
      }}
      data-hover="Read more"
      data-hover-background="#000"
    >
      <div className={styles.content}>
        <h3 className="display5 text-uppercase">Next up</h3>
        <h3 className={styles.title}>
          <span
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <br />
          <span
            style={{
              color: `rgba(${preview[0].highlightColour.red},${preview[0].highlightColour.green},${preview[0].highlightColour.blue},${preview[0].highlightColour.alpha})`,
            }}
            dangerouslySetInnerHTML={{
              __html: titleHighlight,
            }}
          />
        </h3>
      </div>
      <div className={styles.image}>
        <picture>
          <source
            type="image/webp"
            media="(min-width: 1024px)"
            srcSet={`${preview[0].image.url}?w=1500&h=1500&fm=webp`}
          />
          <source
            media="(min-width: 1024px)"
            srcSet={`${preview[0].image.url}?w=1500&h=1500`}
          />
          <source
            type="image/webp"
            media="(min-width: 768px)"
            srcSet={`${preview[0].image.url}?w=1000&h=1000&fm=webp`}
          />
          <source
            media="(min-width: 768px)"
            srcSet={`${preview[0].image.url}?w=800&h=800`}
          />
          <source
            type="image/webp"
            srcSet={`${preview[0].image.url}?w=800&h=800&fm=webp`}
          />
          <img src={`${preview[0].image.url}?w=800&h=800`} />
        </picture>
      </div>
    </Link>
  )
}

export default StaticRelatedCaseStudy
